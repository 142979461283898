import React, { ReactElement } from 'react'
import { parseUrl, isTrustedUrl } from '../../helpers/url'
import PotentialLink from '../potential-link'
import { useGetHrefContext } from '../../context'

export function processLink(
  link?: string,
  currentHref?: string
): string | undefined {
  const { origin } = parseUrl(link) || { origin: undefined }
  const looksExternal = Boolean(origin)
  const parsedCurrentHref = parseUrl(currentHref)
  const reallyInternal =
    (parsedCurrentHref && parsedCurrentHref.origin) === origin
  const isExternal = looksExternal && !reallyInternal
  let processedLink = link
  if (reallyInternal) {
    processedLink = (link || '').slice((origin || '').length)
  } else if (isExternal) {
    processedLink = isTrustedUrl(link)
  }
  return processedLink
}

const AutoPotentialLink: React.FC<
  React.PropsWithChildren<AutoPotentialLinkProps>
> = ({ link, ...props }): ReactElement => {
  const currentHref = useGetHrefContext()()
  const processedLink = processLink(link, currentHref)
  return (
    <div>
      <PotentialLink {...props} link={processedLink} />
    </div>
  )
}

export interface AutoPotentialLinkProps {
  children: React.ReactNode
  link?: string
  className?: string
  onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void
  external?: boolean
}

export default AutoPotentialLink
