import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Link } from '@hub/link'

const PotentialLink: React.FC<React.PropsWithChildren<PotentialLinkProps>> = ({
  children,
  link,
  className,
  onClick,
  ...props
}): JSX.Element => {
  if (link) {
    const commonProps = { ...props, className, onClick }

    return (
      <Link {...commonProps} href={link}>
        {children}
      </Link>
    )
  }

  return <Fragment>{children}</Fragment>
}

interface PotentialLinkProps {
  children: React.ReactNode
  link?: string
  external?: boolean
  className?: string
  onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void
}

PotentialLink.propTypes = {
  children: PropTypes.node.isRequired,
  link: PropTypes.string,
  external: PropTypes.bool,
  className: PropTypes.string,
  onClick: PropTypes.func,
}

export default PotentialLink
